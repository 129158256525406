import {CATEGORY_CHANGED, SUB_CATEGORY_CHANGED} from '../../actions/types';

const INITIAL_STATE = {
  // 'username': '',
  // 'password': ''
  category: 'Product',
  subcategory: ''
};

export default (state = INITIAL_STATE, action) => {
  // console.log('[REDUCER] type:', action.type);
  switch (action.type) {
    case CATEGORY_CHANGED:
      return { ...state, category: action.payload};
    case SUB_CATEGORY_CHANGED:
      return { ...state, subcategory: action.payload};
    default:
      return state;
  }
};
