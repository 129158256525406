//-- Sign --//
export const LOGIN_SUCCESS = 'login_success';
export const LOGIN_FAIL = 'login_fail';
export const LOGIN_USER = 'login_user';
export const LOGIN_CHANGED = 'login_change';
export const LOGIN_RESET = 'login_reset';
export const REGISTER_USER = 'register_user';
export const REGISTER_CHANGED = 'register_change';
export const REGISTER_RESET = 'register_reset';
export const SWITCH_MODE_CHANGED = 'sign_mode_change';
export const REGISTER_SUCCESS = 'register_success';
export const REGISTER_FAIL = 'register_fail';
export const SIGN_CHANGED = 'sign_change';
export const IS_NOT_VERIFIED = 'is_not_verify';
export const REGISTER_UPDATED = 'register_update';
export const CLOSE_ALL_POPUP = 'close_all_popup';
export const LOGIN_GOOGLE_USER = 'login_google_user';

//--KeywordEditor
export const CATEGORY_CHANGED = 'category_changed';
export const SUB_CATEGORY_CHANGED = 'subcategory_changed';
export const ROW_CONTENT_RELOADED = 'row_content_reloaded';
export const ROW_CONTENT_SELECTED = 'row_content_selected';
export const ROW_CONTENT_CHANGED = 'row_content_changed';
export const ROW_CONTENT_CREATED = 'row_content_created';
export const ROW_CONTENT_REMOVED  = 'row_content_removed';
export const ROW_CONTENT_COPIED = 'row_content_copied';
export const ROW_CONTENT_PASTED = 'row_content_pasted';
export const ROW_CONTENT_INIT = 'row_content_init';
export const ROW_CONTENT_INIT_SUCCESS = 'row_content_init_success';
export const ROW_CONTENT_INIT_FAIL = 'row_content_init_fail';
export const PASTE_POPUP_TOGGLE = 'paste_popup_toggle';
export const ADD_DROPDOWN_ONCLICK = 'add_dropdown_onclick';
export const CLONE_DROPDOWN_ONCLICK = 'clone_dropdown_onclick';
export const CLONE_DROPDOWN_ONCHANGE = 'clone_dropdown_changed';
export const ROW_SELECT_MODE_CHANGED = 'row_select_mode_changed';
export const ADD_DROPDOWN_ONCHANGE = 'add_dropdown_changed';
export const ROWS_CONTENT_REMOVED = 'rows_content_removed';
export const MODAL_TOGGLED = 'modal_toggled';
export const ROW_REMOVED = 'row_remove';
export const ROW_ADDED = 'row_add';
export const ROW_COPIED = 'row_copy';
export const UPDATE_LINK_REF = 'link_ref_updated';
export const UPDATE_OPTION = 'option_updated';