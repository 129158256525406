/**
 *  @author: Kenny Chan <kenny.chan@invbot.com>
 *  @since: 2018-12-04
 */

// const fetch = require('node-fetch');

function stringify(obj) {
  let queryString = '';
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      queryString += `${key}=${obj[key]}&`;
    }
  }
  return queryString;
}

export default function fetchPost(url, data, callback) {
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    credentials: 'include',
    body: JSON.stringify(data)
  })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          callback(null, res.status);
        }
      })
      .then(data => {
        callback(data, null);
      })
      .catch(err => {
        callback(null, err);
      });
}

export function fetchPostDownload(url, data, callback) {
  fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    // credentials: "include",
    body: JSON.stringify(data)
  })
      .then(res => {
        if (res.ok) {
          return res.blob();
        } else {
          callback(null, res.status);
        }
      })
      .then(data => {
        callback(data, null);
      })
      .catch(err => {
        callback(null, err);
      });
}

export function fetchGet(url, params, callback) {
  fetch(`${url}?${stringify(params)}`, {})
      .then(res => {
        return res.json();
      })
      .then((data, err) => {
        callback(data, err);
      })
      .catch(function (e) {
        //console.log('--fetchGet error--', e);
      });
}

export function fetchGetCors(url, params, callback) {
  fetch(`${url}?${stringify(params)}`, {
    // headers: { 'Accept': 'application/json', 'Content-Type': 'application/json' },
    credentials: 'include'
  })
      .then(res => {
        return res.json();
      })
      .then((data, err) => {
        callback(data, err);
      })
      .catch(function (e) {
      });
}

export const asyncFetchGet = async (url, params, headers={credentials: 'include'}) => {
  let data;
  console.log(`${url}?${stringify(params)}`);
  let response = await fetch(`${url}?${stringify(params)}`, headers);
  try {
    data = await response.json();
  } catch (e) {
    data = response;
  }
  return data;
};

export const asyncFetchPost = async (url, data) => {
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json'
        },
        // credentials: 'include',
        body: JSON.stringify(data)
    });
    try {
        data = await response.json();
    } catch (e) {
        data = response;
    }
    return data;
};


export function fetchGetDownload(url, params, callback) {
  fetch(`${url}?${stringify(params)}`, {
    headers: {
      credentials: 'include'
    }
  })
      .then(res => res.blob())
      .then((data, err) => {
        callback(data, err);
      });
}

export const asyncFetchGetHTML = async (url, params) =>{
    const res = await fetch(`${url}?${stringify(params)}`, {
        headers: {
            'Content-Type': 'text/html'
        }
    });
    return await res.text();
}

export function fetchPut(url, data, callback) {
  fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
      .then(res => {
        if (res.ok) {
          return res.json();
        } else {
          callback(null, res.status);
        }
      })
      .then(data => {
        callback(data, null);
      })
      .catch(err => {
        callback(null, err);
      });
}

const asyncFetchPut = async (url, data) => {
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    // credentials: 'include',
    body: JSON.stringify(data)
  });
  return await response.json();
};
