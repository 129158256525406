import React, {useEffect, useState} from 'react';
import {HashRouter, Route, Switch} from 'react-router-dom';
import './App.scss';
// Containers
import {DefaultLayout} from './containers';
// Pages
import {Page403, Page404, Page500} from './views/Pages';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as config from './config';
import Login from './views/Pages/Login';
import {fetchGet} from './service/fetchHelperFn';
import {USERS_API} from './utilities/constantsFile';
import {mergeUser} from './utilities/userDataMerge';

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

const PrivateRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest}
           render={
             (props) => rest.isAuthenticated ?
               <Component {...props} /> :
               <Login {...props}/>
           }
    />
  );
};

const App = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    //console.log('@@', isLoading, isAuthenticated);
    // connect to firebase and get user data
    firebase.auth().onAuthStateChanged(async firebaseUser => {
      if (firebaseUser) {
        const invbotsUser = await fetchGet(USERS_API, {firebase_id: firebaseUser.uid});
        // merge invbots user data && firebase user data
        const mergedUser = mergeUser(invbotsUser, firebaseUser);
        //console.log(mergedUser);
        if (mergedUser.isActive && !!mergedUser.intranetUid) {
          setIsAuthenticated(true);
          setIsLoading(false);
        } else {
          setIsAuthenticated(false);
          setIsLoading(false);
        }
      } else {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    });
  });

  if (isLoading) {
    return <p/>;
  } else {
    //console.log('bb');
    return (
      <HashRouter>
        <Switch>
          <Route exact path='/403' name='Page 403' component={Page403}/>
          <Route exact path='/404' name='Page 404' component={Page404}/>
          <Route exact path='/500' name='Page 500' component={Page500}/>
          <PrivateRoute
            isAuthenticated={isAuthenticated}
            path='/'
            name='Home'
            component={DefaultLayout}
            {...props}
          />
        </Switch>
      </HashRouter>
    );
  }
};

export default App;
