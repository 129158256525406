import React, {useState} from 'react';
import {Button, Form, Input} from 'reactstrap';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import * as config from '../../../config';
import {fetchGet} from '../../../service/fetchHelperFn';
import {USERS_API} from '../../../utilities/constantsFile';
import {mergeUser} from '../../../utilities/userDataMerge';

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}

const Login = props => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errMsg, setErrMsg] = useState('');

  const handleSubmit = async e => {
    e.preventDefault();
    if (email && password) {
      // login using firebase JS SDK
      try {
        const res = await firebase.auth().signInWithEmailAndPassword(email, password);
        const firebaseUser = res.user;
        if (firebaseUser) {
          console.log('!~~~', firebaseUser);
          const invbotsUser = await fetchGet(USERS_API, {firebase_id: firebaseUser.uid});
          // merge invbots user data && firebase user data
          const mergedUser = mergeUser(invbotsUser, firebaseUser);
          if (!mergedUser.isActive || !mergedUser.intranetUid)
            setErrMsg('Not Authorized');
        }
      } catch (err) {
        setErrMsg(err.message);
      }
    }
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <Input name='email'
             type='email' onChange={e => setEmail(e.target.value)}
             value={email} placeholder="Email"
             autoComplete='email'/>
      <Input name='password' type='password' value={password} placeholder="Password"
             onChange={e => setPassword(e.target.value)}
             autoComplete='current-password'/>
      <p style={{color: 'red'}}>{errMsg}</p>
      <p style={{color: 'red'}}>{props.errMsg}</p>
      <Button block className="btn" color="danger" type='submit'>
        Submit
      </Button>
    </Form>
  );
};

export default Login;
