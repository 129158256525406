import React, {Component} from 'react';
import {isEmpty} from '../../utilities/getUserData';

export default class CheckAuthorization extends Component { // check current role
  constructor(props) {
    super(props);
  }

  checkAuth = callback => {
    const {path, feedUserDetails, feedAuthJson, loopAllLayers} = this.props;
    const {role} = feedUserDetails;
    let isAuth = false;
    let sliceIndex = 0;

    while (sliceIndex < path.length) {
      // check the authentication layer by layer (top-down) if loopAllLayers === true
      sliceIndex = path.indexOf('/', sliceIndex + 1);
      if (sliceIndex < 0 || !loopAllLayers) sliceIndex = path.length;
      let nav = path.slice(0, sliceIndex);
      let component = feedAuthJson[nav];

      // initialize the auth flag as false and loop through the role property if the component is found in the authJson
      if (component === undefined) {
        isAuth = true;
        continue;
      } else isAuth = false;
      role.forEach(_role => {
        if (component['role'].indexOf(_role) > -1) isAuth = true;
      });

      // return false if the path is blocked by precedents
      if (!isAuth) break;
    }

    return callback(isAuth);
  };

  componentDidMount() {
    if (!isEmpty(this.props.feedUserDetails))
      this.checkAuth(result => this.props.authenticateHandler(result));
    else this.props.authenticateHandler(false);
  };

  render() {
    return null;
  }
}
