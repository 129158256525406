import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from 'reactstrap';
import {signOut} from '../../utilities/getUserData';

const getRandomInt = max => Math.floor(Math.random() * Math.floor(max));

const DefaultHeaderDropdown = props => {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const onClickHandler = async e => {
    e.preventDefault();
    await signOut();
    window.location.reload();
  };

  const dropAccnt = () => {
    return (
      <Dropdown nav className="d-md-down-none" isOpen={dropdownOpen} toggle={() => setDropdownOpen(!dropdownOpen)}>
        <DropdownToggle nav>
          <img src={props.feedUser.profilePhoto || `assets/img/avatars/${getRandomInt(8)}.jpg`} className="img-avatar"
               title={props.feedUser.displayName ? props.feedUser.displayName : 'Guest'} alt=''/>
        </DropdownToggle>
        <DropdownMenu right className="dropdown-menu-lg">
          <DropdownItem header tag="div"
                        className="text-center"><strong>Welcome, {props.feedUser.displayName ? props.feedUser.displayName : 'Guest'} </strong></DropdownItem>
          <DropdownItem header tag="div"
                        className="text-center"><img src={props.feedUser.profilePhoto || `assets/img/avatars/${getRandomInt(8)}.jpg`} className="img-avatar"
                                                     title={props.feedUser.displayName ? props.feedUser.displayName : 'Guest'} alt=''/></DropdownItem>
          <DropdownItem className="text-center" onClick={onClickHandler.bind(this)}><i className="fa fa-sign-out"/> Sign Out</DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  const {accnt} = props;
  return accnt ? dropAccnt() : null;
};

export default DefaultHeaderDropdown;
