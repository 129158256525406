import {API_SERVER_URL, DATA_SERVER_URL, EQUATION_BUILDER_URL, GRAPHQL_URL, INTRANET_API_SERVER_URL, REPORT_EDIT_URL, WEB_API_SERVER_URL} from '../utilities/constantsFile';
import fetchPost, {asyncFetchGet, asyncFetchPost, fetchGet, fetchPut} from './sendRequest';
import {asyncGetUserData} from '../utilities/getUserData';

export const GraphQLGET = async query => {
  try {
    return await asyncFetchGet(GRAPHQL_URL, query);
  } catch (e) {
    console.error(e);
  }
};


export default function searchTable(data, callback) {
  fetchGet(REPORT_EDIT_URL + '/search-table', data, callback);
}

export function searchTicker(data, callback) {
  fetchGet(REPORT_EDIT_URL + '/search-ticker', data, callback);
}

export function reportSpecial(data, callback) {
  fetchPost(REPORT_EDIT_URL + '/report-special', data, callback);
}

export function getSpecialCase(callback) {
  fetchGet(REPORT_EDIT_URL + '/special-case', {}, callback);
}

export function revertTable(data, callback) {
  fetchPost(REPORT_EDIT_URL + '/revert-table', data, callback);
}

export function getRevertTable(data, callback) {
  fetchGet(REPORT_EDIT_URL + '/get-revert-table', data, callback);
}

export function getTaskAllocation(data, callback) {
  fetchGet(REPORT_EDIT_URL + '/task-allocation', data, callback);
}

export function createTable(data, callback) {
  fetchPost(REPORT_EDIT_URL + '/createTableHeader', data, callback);
}

export function submitTable(data, callback) {
  fetchPost(REPORT_EDIT_URL + '/submit-table', data, callback);
}

export function reportTest(data, callback) {
  fetchPost(REPORT_EDIT_URL + '/reportTest', data, callback);
}

export function getReportContent(callback) {
  fetchGet(DATA_SERVER_URL + '/intranet/get_report_content', {}, callback);
}

// export function getAllReports(data, callback) {
//   fetchGet(DATA_SERVER_URL, '/intranet/get_all_reports', data, callback);
// }

export function allTableList(data, callback) {
  fetchGet(REPORT_EDIT_URL + '/allTableList', data, callback);
}

export function getRelationships(data, callback) {
  fetchGet(DATA_SERVER_URL + '/intranet/getRelationships', data, callback);
}

export function getScheduleJobData(callback) {
  fetchGet(DATA_SERVER_URL + '/getScheduleJobData', {}, callback);
}

export function getUserData(data, callback) {
  fetchGet(WEB_API_SERVER_URL + '/users', data, callback);
}

export const insertUserData = async data => {
  try {
    const res = await fetch(WEB_API_SERVER_URL + '/users', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return res.json();
  } catch (e) {
    return e;
  }
};

export const updateUserData = async data => {
  try {
    const res = await fetch(WEB_API_SERVER_URL + '/users', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
    return res.json();
  } catch (e) {
    return e;
  }
};

export function getXbrlKey(data, callback) {
  fetchGet(DATA_SERVER_URL + '/xbrl_key', data, callback);
}

export function createXbrlKey(data, callback) {
  fetchPost(DATA_SERVER_URL + '/xbrl_key', data, callback);
}

export function updateUsersData(data, callback) {
  fetchPost(DATA_SERVER_URL + '/intranet/updateUsersData', data, callback);
}

export function insertCompanyMappingRelForm(data, callback) {
  fetchPost(DATA_SERVER_URL + '/intranet/updateUsersData', data, callback);
}

export function insertCompanyMappingInfoForm(data, callback) {
  fetchPost(DATA_SERVER_URL + '/intranet/insertCompanyMappingInfoForm', data, callback);
}

export function getMaintenanceStatus(callback) {
  fetchGet('/');
}

export function getInputData(data, callback) {
  fetchGet(DATA_SERVER_URL + '/api/getInput', data, callback);
}

export function getAllBetaTests(callback) {
  fetchGet(INTRANET_API_SERVER_URL + '/beta-test/get-all-tests', null, callback);
}

export function getOneBetaTest(data, callback) {
  fetchGet(INTRANET_API_SERVER_URL + '/beta-test/get-one-test', data, callback);
}

export function createBetaTest(data, callback) {
  fetchGet(INTRANET_API_SERVER_URL + '/beta-test/insert-test', data, callback);
}

export function removeBetaTest(data, callback) {
  fetchGet(INTRANET_API_SERVER_URL + '/beta-test/remove-test', data, callback);
}

export function updateBetaTest(data, callback) {
  console.log(data);
  fetchGet(INTRANET_API_SERVER_URL + '/beta-test/update-test', data, callback);
}

export function getInvBotsKeys(callback) {
  fetchGet(EQUATION_BUILDER_URL + '/key', {}, callback);
}

export function getEqtnRatios(callback) {
  fetchGet(EQUATION_BUILDER_URL + '/ratios', {}, callback);
}

export function getEqtnUnits(callback, selectedRatio){
  let url = EQUATION_BUILDER_URL  + '/units/' + selectedRatio;
  fetchGet(url, {}, callback);
}

export function getBetaTestJson(url, method, callback) {
  console.log('AA', url, method);
  if (method === 'GET') fetchGet(url, null, callback);
  else if (method === 'POST') fetchPost(url, null, callback);
  else return null;
}

export function triggerBetaTestProgram(url, method, data, callback) {
  console.log(url, method, data);
  if (method === 'GET') fetchGet(url, data, callback);
  else if (method === 'POST') fetchPost(url, data, callback);
  else return null;
}

// export async function readConfig(filename, callback){
//   await fetchGet(`/resource/config/${filename}/read`, null, callback);
// }

export async function readConfig(filename) {
  return await asyncFetchGet(`/resource/config/${filename}/read`, null);
}

export function addEquation(data, callback){
  fetchPost(EQUATION_BUILDER_URL + '/submit', data, callback)
  //fetchPost(INTRANET_API_SERVER_URL + '/graphql', object, callback)
}

export function writeConfig(data, callback) {
  const {filename, payload} = data;
  fetchGet(`/resource/config/${filename}/write`, {payload: JSON.stringify(payload)}, callback);
}

export function removeConfig(data, callback) {
  const {filename, payload} = data;
  fetchGet(`/resource/config/${filename}/remove`, {payload: JSON.stringify(payload)}, callback);
}

export function updateConfig(data, callback) {
  const {filename, payload} = data;
  fetchGet(`/resource/config/${filename}/update`, {payload: JSON.stringify(payload)}, callback);
}

export function saveInput(data, callback) {
  fetchPost(API_SERVER_URL + '/saveInput', {items: data}, callback);
}

export function getRssDocuments(data, callback) {
  fetchGet(INTRANET_API_SERVER_URL + '/us-modelbots/8k/rssDocuments', data, callback);
}

export const getCompanyMappingGUITree = async data => {
  return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/gui/tree`, data, {}).then(result => {
    console.log(result);
    return result;
  });
};

export const searchCompanyMappingInfo = async data => {
  return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/company-info/search`, data, {}).then(result => {
    console.log(result);
    return result;
  });
};

export const searchCompanyMappingKeyword = async data => {
  return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/keyword/search`, data, {}).then(result => {
    console.log(result);
    return result;
  });
};

export const createCompanyMappingCategory = async data => {
  return await asyncFetchGet('${INTRANET_API_SERVER_URL}/company-mapping/', data, {}).then(result => {
    console.log(result);
    return result;
  });
};

export const getCompany = async data => {
  return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/company', data).then(result => {
    console.log(result);
    return result;
  });
};

export const getAllCategory = async data => {
  return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/category`, data).then(result => {
    console.log(result);
    return result;
  });
};

export const searchCategory = async data => {
  return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/category/search${INTRANET_API_SERVER_URL}/company-mapping/category/search`, data).then(result => {
    console.log(result);
    return result;
  });
};

export const insertReport = async data => {
  const response = await asyncGetUserData();
  if (response) {
    const {user} = response;
    data = {...data, createUid: user.intranetUid};
    const url = `${INTRANET_API_SERVER_URL}/company-mapping/report`;
    // const url = `http://localhost:3001/intranet-api/company-mapping/report`;
    return await asyncFetchPost(url, data);
  } else return false;
};

export const getKeyword = async data => {
  return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/keyword', data).then(result => {
    console.log(result);
    return result;
  });
};

export const getCompanyByKeyword = async (inputText, data) => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/companyByKeyword/' + inputText, data).then(result=>{
  //   console.log(result);
  //   return result
  // })
  return [
    {
      'ticker': 'NLSN',
      'approve_uid': '',
      'report_id': '8',
      'name': 'Nielsen Holdings Plc',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '8',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    },
    {
      'ticker': 'NOV',
      'approve_uid': '',
      'report_id': '12',
      'count': 0,
      'name': 'National Oilwell Varco Inc.',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '2',
      'state': 0,
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'GILD',
      'approve_uid': '',
      'report_id': '23',
      'name': 'Gilead Sciences Inc.',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '3',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'VRTX',
      'approve_uid': '',
      'report_id': '26',
      'count': 0,
      'name': 'Vertex Pharmaceuticals Incorporated',
      'exchange': 'NasdaqGS',
      'countryOfIncorporation': '',
      'update_uid': '6',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'REGN',
      'approve_uid': '',
      'report_id': '28',
      'count': 0,
      'name': 'Regeneron Pharmaceuticals Inc.',
      'exchange': 'NasdaqGS',
      'countryOfIncorporation': '',
      'update_uid': '8',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    },
    {
      'ticker': 'ALXN',
      'approve_uid': '',
      'report_id': '31',
      'count': 0,
      'name': 'Alexion Pharmaceuticals Inc.',
      'exchange': 'NasdaqGS',
      'countryOfIncorporation': '',
      'update_uid': '1',
      'state': 0,
      'create_uid': '1',
      'frequency': 1
    },
    {
      'ticker': 'AXP',
      'approve_uid': '',
      'report_id': '40',
      'name': 'American Express Company',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '0',
      'state': 0,
      'create_uid': '0',
      'frequency': 1
    },
    {
      'ticker': 'NKE',
      'approve_uid': '',
      'report_id': '82',
      'name': 'NIKE Inc. Class B',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '2',
      'state': 0,
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'AMD',
      'approve_uid': '',
      'report_id': '59',
      'count': 0,
      'name': 'Advanced Micro Devices Inc.',
      'exchange': 'NASDAQ',
      'countryOfIncorporation': '',
      'update_uid': '9',
      'state': 0,
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'INTC',
      'approve_uid': '',
      'report_id': '60',
      'name': 'Intel Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '0',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '0',
      'frequency': 1
    },
    {
      'ticker': 'EOG',
      'approve_uid': '',
      'report_id': '64',
      'name': 'EOG Resources Inc.',
      'count': 0,
      'exchange': 'NYSE ',
      'countryOfIncorporation': '',
      'update_uid': '4',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'COP',
      'approve_uid': '',
      'report_id': '65',
      'name': 'ConocoPhillips',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '5',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '5',
      'frequency': 1
    },
    {
      'ticker': 'CAH',
      'approve_uid': '',
      'report_id': '88',
      'name': 'Cardinal Health Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '8',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    },
    {
      'ticker': 'HSIC',
      'approve_uid': '',
      'report_id': '89',
      'name': 'Henry Schein Inc.',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '9',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'BK',
      'approve_uid': '',
      'report_id': '95',
      'count': 0,
      'name': 'Bank of New York Mellon Corporation',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '5',
      'state': 0,
      'create_uid': '5',
      'frequency': 1
    },
    {
      'ticker': 'BLK',
      'approve_uid': '',
      'report_id': '96',
      'name': 'BlackRock Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '6',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'IVZ',
      'approve_uid': '',
      'report_id': '97',
      'name': 'Invesco Ltd.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '7',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'UTX',
      'approve_uid': '',
      'report_id': '110',
      'count': 0,
      'name': 'United Technologies Corporation',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '0',
      'state': 0,
      'create_uid': '0',
      'frequency': 1
    },
    {
      'ticker': 'ARNC',
      'approve_uid': '',
      'report_id': '116',
      'name': 'Arconic Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '6',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'VFC',
      'approve_uid': '',
      'report_id': '126',
      'name': 'V.F. Corporation',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '6',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'UAA',
      'approve_uid': '',
      'report_id': '127',
      'name': 'Under Armour Inc. Class A',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '7',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'IPGP',
      'approve_uid': '',
      'report_id': '129',
      'name': 'IPG Photonics Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '9',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'AMAT',
      'approve_uid': '',
      'report_id': '135',
      'name': 'Applied Materials Inc.',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '5',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '5',
      'frequency': 1
    },
    {
      'ticker': 'LRCX',
      'approve_uid': '',
      'report_id': '136',
      'name': 'Lam Research Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '6',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'GRMN',
      'approve_uid': '',
      'report_id': '137',
      'name': 'Garmin Ltd.',
      'count': 0,
      'exchange': 'NasdaqGS',
      'countryOfIncorporation': '',
      'update_uid': '7',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'APH',
      'approve_uid': '',
      'report_id': '139',
      'count': 0,
      'name': 'Amphenol Corporation Class A',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '9',
      'state': 0,
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'GLW',
      'approve_uid': '',
      'report_id': '140',
      'name': 'Corning Inc',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '0',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '0',
      'frequency': 1
    },
    {
      'ticker': 'WMT',
      'approve_uid': '',
      'report_id': '142',
      'name': 'Walmart Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '2',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'SYK',
      'approve_uid': '',
      'report_id': '153',
      'name': 'Stryker Corporation',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '3',
      'state': 0,
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'ISRG',
      'approve_uid': '',
      'report_id': '162',
      'name': 'Intuitive Surgical Inc.',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '2',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'JPM',
      'approve_uid': '',
      'report_id': '172',
      'count': 0,
      'name': 'JPMorgan Chase & Co.',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '2',
      'state': 0,
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'C',
      'approve_uid': '',
      'report_id': '173',
      'name': 'Citigroup Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '3',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'USB',
      'approve_uid': '',
      'report_id': '174',
      'name': 'U.S. Bancorp',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '4',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'KEYS',
      'approve_uid': '',
      'report_id': '178',
      'name': 'Keysight Technologies Inc',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '8',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    },
    {
      'ticker': 'GM',
      'approve_uid': '',
      'report_id': '0',
      'name': 'General Motors Company',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '0',
      'state': 0,
      'create_uid': '0',
      'frequency': 5
    },
    {
      'ticker': 'F',
      'approve_uid': '',
      'report_id': '1',
      'name': 'Ford Motor Company',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '1',
      'state': 0,
      'create_uid': '1',
      'frequency': 2
    },
    {
      'ticker': 'BF.B',
      'approve_uid': '',
      'report_id': '4',
      'count': 0,
      'name': 'Brown-Forman Corporation Class B',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '4',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'FBHS',
      'approve_uid': '',
      'report_id': '6',
      'name': 'Fortune Brands Home & Security Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '6',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'AOS',
      'approve_uid': '',
      'report_id': '7',
      'name': 'A. O. Smith Corporation',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '7',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'NVDA',
      'approve_uid': '',
      'report_id': '58',
      'name': 'NVIDIA Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '8',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '8',
      'frequency': 2
    }
  ];
};

export const getCompanyByCategory = async (inputText, data) => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/companyByCategory/' + inputText, data).then(result=>{
  //   console.log(result);
  //   return result
  // })
  return [
    {
      'ticker': 'FLIR',
      'approve_uid': '',
      'report_id': '179',
      'name': 'FLIR Systems Inc.',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '9',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'KEYS',
      'approve_uid': '',
      'report_id': '178',
      'name': 'Keysight Technologies Inc',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '8',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    },
    {
      'ticker': 'GRMN',
      'approve_uid': '',
      'report_id': '137',
      'name': 'Garmin Ltd.',
      'count': 0,
      'exchange': 'NasdaqGS',
      'countryOfIncorporation': '',
      'update_uid': '7',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'DISH',
      'approve_uid': '',
      'report_id': '134',
      'count': 0,
      'name': 'DISH Network Corporation Class A',
      'exchange': 'Nasdaq',
      'countryOfIncorporation': '',
      'update_uid': '4',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'LYB',
      'approve_uid': '',
      'report_id': '117',
      'count': 0,
      'name': 'LyondellBasell Industries NV',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '7',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'TXT',
      'approve_uid': '',
      'report_id': '107',
      'name': 'Textron Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '7',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'LMT',
      'approve_uid': '',
      'report_id': '111',
      'count': 0,
      'name': 'Lockheed Martin Corporation',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '1',
      'state': 0,
      'create_uid': '1',
      'frequency': 1
    },
    {
      'ticker': 'RTN',
      'approve_uid': '',
      'report_id': '106',
      'name': 'Raytheon Company',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '6',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'SNPS',
      'approve_uid': '',
      'report_id': '83',
      'name': 'Synopsys Inc.',
      'count': 0,
      'exchange': 'NasdaqGS',
      'countryOfIncorporation': '',
      'update_uid': '3',
      'state': 0,
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'GPN',
      'approve_uid': '',
      'report_id': '81',
      'name': 'Global Payments Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '1',
      'state': 0,
      'create_uid': '1',
      'frequency': 1
    },
    {
      'ticker': 'FLT',
      'approve_uid': '',
      'report_id': '80',
      'count': 0,
      'name': 'FleetCor Technologies Inc.',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '0',
      'state': 0,
      'create_uid': '0',
      'frequency': 1
    },
    {
      'ticker': 'ADS',
      'approve_uid': '',
      'report_id': '78',
      'count': 0,
      'name': 'Alliance Data Systems Corporation',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '8',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    },
    {
      'ticker': 'ADP',
      'approve_uid': '',
      'report_id': '74',
      'count': 0,
      'name': 'Automatic Data Processing Inc.',
      'exchange': 'Nasdaq',
      'countryOfIncorporation': '',
      'update_uid': '4',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'MA',
      'approve_uid': '',
      'report_id': '72',
      'count': 0,
      'name': 'Mastercard Incorporated Class A',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '2',
      'state': 0,
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'V',
      'approve_uid': '',
      'report_id': '71',
      'name': 'Visa Inc. Class A',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '1',
      'state': 0,
      'create_uid': '1',
      'frequency': 1
    },
    {
      'ticker': 'COP',
      'approve_uid': '',
      'report_id': '65',
      'name': 'ConocoPhillips',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '5',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '5',
      'frequency': 1
    },
    {
      'ticker': 'EOG',
      'approve_uid': '',
      'report_id': '64',
      'name': 'EOG Resources Inc.',
      'count': 0,
      'exchange': 'NYSE ',
      'countryOfIncorporation': '',
      'update_uid': '4',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'LRCX',
      'approve_uid': '',
      'report_id': '136',
      'name': 'Lam Research Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '6',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'INTC',
      'approve_uid': '',
      'report_id': '60',
      'name': 'Intel Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '0',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '0',
      'frequency': 1
    },
    {
      'ticker': 'ISRG',
      'approve_uid': '',
      'report_id': '162',
      'name': 'Intuitive Surgical Inc.',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '2',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'AMD',
      'approve_uid': '',
      'report_id': '59',
      'count': 0,
      'name': 'Advanced Micro Devices Inc.',
      'exchange': 'NASDAQ',
      'countryOfIncorporation': '',
      'update_uid': '9',
      'state': 0,
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'IBM',
      'approve_uid': '',
      'report_id': '103',
      'name': 'International Business Machines Corporation',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '3',
      'state': 0,
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'BR',
      'approve_uid': '',
      'report_id': '79',
      'count': 0,
      'name': 'Broadridge Financial Solutions Inc.',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '9',
      'state': 0,
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'PYPL',
      'approve_uid': '',
      'report_id': '73',
      'name': 'PayPal Holdings Inc',
      'count': 0,
      'exchange': 'Nasdaq',
      'update_uid': '3',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'NVDA',
      'approve_uid': '',
      'report_id': '58',
      'name': 'NVIDIA Corporation',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '8',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '8',
      'frequency': 2
    },
    {
      'ticker': 'JPM',
      'approve_uid': '',
      'report_id': '172',
      'count': 0,
      'name': 'JPMorgan Chase & Co.',
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '2',
      'state': 0,
      'create_uid': '2',
      'frequency': 1
    },
    {
      'ticker': 'KR',
      'approve_uid': '',
      'report_id': '143',
      'name': 'Kroger Co.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '3',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'PAYX',
      'approve_uid': '',
      'report_id': '77',
      'name': 'Paychex Inc.',
      'count': 0,
      'exchange': 'Nasdaq',
      'countryOfIncorporation': '',
      'update_uid': '7',
      'state': 0,
      'create_uid': '7',
      'frequency': 1
    },
    {
      'ticker': 'FISV',
      'approve_uid': '',
      'report_id': '76',
      'name': 'Fiserv Inc.',
      'count': 0,
      'exchange': 'Nasdaq',
      'countryOfIncorporation': '',
      'update_uid': '6',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'FIS',
      'approve_uid': '',
      'report_id': '75',
      'name': 'Fidelity National Information Services Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '5',
      'state': 0,
      'create_uid': '5',
      'frequency': 1
    },
    {
      'ticker': 'SYF',
      'approve_uid': '',
      'report_id': '39',
      'name': 'Synchrony Financial',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '9',
      'state': 0,
      'create_uid': '9',
      'frequency': 1
    },
    {
      'ticker': 'GOOG',
      'approve_uid': '',
      'report_id': '33',
      'name': 'Alphabet Inc. Class C',
      'count': 0,
      'exchange': 'NASDAQ',
      'update_uid': '3',
      'state': 0,
      'countryOfIncorporation': '',
      'create_uid': '3',
      'frequency': 1
    },
    {
      'ticker': 'CRM',
      'approve_uid': '',
      'report_id': '84',
      'name': 'salesforce.com inc.',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '4',
      'state': 0,
      'create_uid': '4',
      'frequency': 1
    },
    {
      'ticker': 'BLK',
      'approve_uid': '',
      'report_id': '96',
      'name': 'BlackRock Inc.',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '6',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '6',
      'frequency': 1
    },
    {
      'ticker': 'OMC',
      'approve_uid': '',
      'report_id': '21',
      'name': 'Omnicom Group Inc',
      'count': 0,
      'exchange': 'NYSE',
      'countryOfIncorporation': '',
      'update_uid': '1',
      'state': 0,
      'create_uid': '1',
      'frequency': 1
    },
    {
      'ticker': 'FTI',
      'approve_uid': '',
      'report_id': '18',
      'name': 'TechnipFMC Plc',
      'count': 0,
      'exchange': 'NYSE',
      'update_uid': '8',
      'countryOfIncorporation': '',
      'state': 0,
      'create_uid': '8',
      'frequency': 1
    }
  ];
};

export const getKeywordByCompany = async (data) => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/keywordByCompany', data).then(result=>{
  //   console.log(result);
  //   return result
  // })
  return [
    {
      'name': 'Jetson AGX',
      'count': 1,
      'belongTo': 'processor',
      'label': 'jetson agx',
      'state': 0
    },
    {
      'name': 'SHIELD',
      'count': 1,
      'belongTo': 'processor',
      'label': 'shield',
      'state': 0
    },
    {
      'name': 'Clara AGX',
      'count': 1,
      'belongTo': 'processor',
      'label': 'clara agx',
      'state': 0
    },
    {
      'name': 'DRIVE AGX',
      'count': 1,
      'belongTo': 'processor',
      'label': 'drive agx',
      'state': 0
    },
    {
      'name': 'Tegra',
      'count': 1,
      'belongTo': 'processor',
      'label': 'tegra',
      'state': 0
    },
    {
      'name': 'DGX',
      'count': 1,
      'belongTo': 'gpu',
      'label': 'dgx',
      'state': 0
    },
    {
      'name': 'GRID',
      'count': 1,
      'belongTo': 'gpu',
      'label': 'grid',
      'state': 0
    },
    {
      'name': 'Tesla',
      'count': 1,
      'belongTo': 'gpu',
      'label': 'tesla',
      'state': 0
    },
    {
      'name': 'Quadro',
      'count': 1,
      'belongTo': 'gpu',
      'label': 'quadro',
      'state': 0
    },
    {
      'name': 'GeForce NOW',
      'count': 1,
      'belongTo': 'gpu',
      'label': 'geforce now',
      'state': 0
    },
    {
      'name': 'GeForce',
      'count': 1,
      'belongTo': 'gpu',
      'label': 'geforce',
      'state': 0
    },
    {
      'name': 'Autonomous driving',
      'count': 1,
      'belongTo': 'technology',
      'label': 'autonomous driving',
      'state': 0
    },
    {
      'name': 'Virtual reality',
      'count': 3,
      'belongTo': 'technology',
      'label': 'virtual reality',
      'state': 0
    },
    {
      'name': 'Deep learning',
      'count': 1,
      'belongTo': 'technology',
      'label': 'deep learning',
      'state': 0
    },
    {
      'name': 'Machine learning',
      'count': 3,
      'belongTo': 'technology',
      'label': 'machine learning',
      'state': 0
    },
    {
      'name': 'Artificial intelligence',
      'count': 7,
      'belongTo': 'technology',
      'label': 'artificial intelligence',
      'state': 0
    },
    {
      'name': 'US-China Trade War',
      'count': 11,
      'belongTo': 'international affair',
      'label': 'us-china trade war',
      'state': 0
    },
    {
      'name': 'Asia',
      'count': 27,
      'belongTo': 'region',
      'label': 'asia',
      'state': 0
    },
    {
      'name': 'Europe',
      'count': 70,
      'belongTo': 'region',
      'label': 'europe',
      'state': 0
    },
    {
      'name': 'United States',
      'count': 111,
      'belongTo': 'country',
      'label': 'united states',
      'state': 0
    },
    {
      'name': 'Korea',
      'count': 8,
      'belongTo': 'country',
      'label': 'korea',
      'state': 0
    },
    {
      'name': 'China',
      'count': 43,
      'belongTo': 'country',
      'label': 'china',
      'state': 0
    },
    {
      'name': 'Taiwan',
      'count': 14,
      'belongTo': 'country',
      'label': 'taiwan',
      'state': 0
    },
    {
      'name': 'United States',
      'count': 111,
      'belongTo': 'country',
      'label': 'united states',
      'state': 0
    },
    {
      'name': 'China',
      'count': 43,
      'belongTo': 'country',
      'label': 'china',
      'state': 0
    },
    {
      'name': 'Hong Kong',
      'count': 7,
      'belongTo': 'country',
      'label': 'hong kong',
      'state': 0
    },
    {
      'name': 'Taiwan',
      'count': 14,
      'belongTo': 'country',
      'label': 'taiwan',
      'state': 0
    },
    {
      'name': 'USD',
      'count': 77,
      'belongTo': 'currency',
      'label': 'usd',
      'state': 0
    },
    {
      'name': 'Application Software',
      'count': 3,
      'belongTo': 'industry',
      'label': 'application software',
      'state': 0
    },
    {
      'name': 'Data Processing & Outsourced Services',
      'count': 9,
      'belongTo': 'industry',
      'label': 'data processing & outsourced services',
      'state': 0
    },
    {
      'name': 'Health Care Technology',
      'count': 1,
      'belongTo': 'industry',
      'label': 'health care technology',
      'state': 0
    },
    {
      'name': 'Automobile Manufacturers',
      'count': 5,
      'belongTo': 'industry',
      'label': 'automobile manufacturers',
      'state': 0
    },
    {
      'name': 'Computer & Electronics Retail',
      'count': 1,
      'belongTo': 'industry',
      'label': 'computer & electronics retail',
      'state': 0
    },
    {
      'name': 'Semiconductors',
      'count': 5,
      'belongTo': 'industry',
      'label': 'semiconductors',
      'state': 0
    }
  ];
};

export const getRowsByCompany = async (input, data) => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/instanceByCompany/' + input, data).then(result => {
  //   console.log(result);
  //   return result;
  // })
  return [
    {
      'trend': '0',
      'last_update': '2019-7-27 12:6:36.77',
      'link': 'https://www.sec.gov/Archives/edgar/data/93751/000009375119000336/stt-20181231_10k.htm',
      'state': {
        'low': 0,
        'high': 0
      },
      'source': '2018 Annual Repoet',
      'rationale': 'Product',
      'relevance': '1',
      'frequency': {
        'low': 1,
        'high': 0
      },
      'category': 'Service',
      'subcategory': 'Investment Service',
      'keyword': 'brokerage',
      'count': {
        'low': 3,
        'high': 0
      },
      'score': 100,
      'finalScore': 4
    },
    {
      'trend': '0',
      'last_update': '2019-7-27 12:6:36.77',
      'link': 'https://www.sec.gov/Archives/edgar/data/93751/000009375119000336/stt-20181231_10k.htm',
      'state': {
        'low': 0,
        'high': 0
      },
      'source': '2018 Annual Repoet',
      'rationale': 'Product',
      'relevance': '1',
      'frequency': {
        'low': 1,
        'high': 0
      },
      'category': 'product',
      'subcategory': 'Investment Service',
      'keyword': 'brokerage',
      'count': {
        'low': 3,
        'high': 0
      },
      'score': 100,
      'finalScore': 4
    },
    {
      'trend': '0',
      'last_update': '2019-7-27 12:6:34.41',
      'link': 'https://www.sec.gov/Archives/edgar/data/93751/000009375119000336/stt-20181231_10k.htm',
      'state': {
        'low': 0,
        'high': 0
      },
      'source': '2018 Annual Repoet',
      'rationale': 'Customer',
      'relevance': '1',
      'frequency': {
        'low': 1,
        'high': 0
      },
      'category': 'Country',
      'keyword': 'Luxemberg',
      'count': {
        'low': 2,
        'high': 0
      },
      'score': 100,
      'finalScore': 3.6666666666666665
    },
    {
      'trend': '0',
      'last_update': '2019-7-27 12:6:34.41',
      'link': 'https://www.sec.gov/Archives/edgar/data/93751/000009375119000336/stt-20181231_10k.htm',
      'state': {
        'low': 0,
        'high': 0
      },
      'source': '2018 Annual Repoet',
      'rationale': 'Customer',
      'relevance': '1',
      'frequency': {
        'low': 1,
        'high': 0
      },
      'category': 'country',
      'keyword': 'Luxemberg',
      'count': {
        'low': 2,
        'high': 0
      },
      'score': 100,
      'finalScore': 3.6666666666666665
    },
    {
      'trend': '0',
      'last_update': '2019-7-27 12:6:38.107',
      'link': 'https://www.sec.gov/Archives/edgar/data/93751/000009375119000336/stt-20181231_10k.htm',
      'state': {
        'low': 0,
        'high': 0
      },
      'source': '2018 Annual Repoet',
      'rationale': 'Product',
      'relevance': '1',
      'frequency': {
        'low': 1,
        'high': 0
      },
      'category': 'Service',
      'subcategory': 'Investment Service',
      'keyword': 'depotbank services',
      'count': {
        'low': 1,
        'high': 0
      },
      'score': 100,
      'finalScore': 3.3333333333333335
    },
    {
      'trend': '0',
      'last_update': '2019-7-27 12:6:38.107',
      'link': 'https://www.sec.gov/Archives/edgar/data/93751/000009375119000336/stt-20181231_10k.htm',
      'state': {
        'low': 0,
        'high': 0
      },
      'source': '2018 Annual Repoet',
      'rationale': 'Product',
      'relevance': '1',
      'frequency': {
        'low': 1,
        'high': 0
      },
      'category': 'product',
      'subcategory': 'Investment Service',
      'keyword': 'depotbank services',
      'count': {
        'low': 1,
        'high': 0
      },
      'score': 100,
      'finalScore': 3.3333333333333335
    }
  ];
};

export const searchKeyword = async (input, data) => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/keyword/' + input, data).then(result => {
  //   console.log(result);
  //   return result;
  // })
  return [
    {
      'name': 'brokerage'
    },
    {
      'name': 'brokerage services'
    }
  ];
};

export const getReports = async data => {
  // console.log(data);
  const {reportId} = data;
  if (reportId)
  // return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/report/${reportId}`, data).then(result => {
    return await asyncFetchGet(`http://localhost:3001/intranet-api/company-mapping/report/${reportId}`, data).then(result => {
      // console.log(result);
      return result;
    });
  else
    return await asyncFetchGet(`${INTRANET_API_SERVER_URL}/company-mapping/report/search`, data).then(result => {
      // return await asyncFetchGet(`http://localhost:3001/intranet-api/company-mapping/report/search`, data).then(result => {
      // console.log(result);
      return result;
    });

};

export const getReportsByUid = async data => {
  const {intranetUid} = data;
  // return await asyncFetchGet('http://localhost:3004/web/v1' + `/companyMapping/reportsByuid/${intranetUid}`, data).then(result => {
  //   console.log(result);
  //   return result;
  // })
  return [{
    ticker: 'NOV',
    approve_uid: '',
    report_id: '12',
    count: 0,
    name: 'National Oilwell Varco Inc.',
    exchange: 'NYSE',
    countryOfIncorporation: '',
    update_uid: '2',
    state: 0,
    create_uid: '2',
    frequency: 1
  },
    {
      ticker: 'IPG',
      approve_uid: '',
      report_id: '22',
      count: 0,
      name: 'Interpublic Group of Companies Inc.',
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'CTL',
      approve_uid: '',
      report_id: '32',
      name: 'CenturyLink Inc.',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'DE',
      approve_uid: '',
      report_id: '42',
      name: 'Deere & Company',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'MA',
      approve_uid: '',
      report_id: '72',
      count: 0,
      name: 'Mastercard Incorporated Class A',
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'NKE',
      approve_uid: '',
      report_id: '82',
      name: 'NIKE Inc. Class B',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'CXO',
      approve_uid: '',
      report_id: '62',
      name: 'Concho Resources Inc.',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'UPS',
      approve_uid: '',
      report_id: '92',
      count: 0,
      name: 'United Parcel Service Inc. Class B',
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'FL',
      approve_uid: '',
      report_id: '102',
      name: 'Foot Locker Inc.',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'HRS',
      approve_uid: '',
      report_id: '112',
      name: 'Harris Corporation',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'CPRI',
      approve_uid: '',
      report_id: '122',
      name: 'Capri Holdings Limited',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'CMCSA',
      approve_uid: '',
      report_id: '132',
      count: 0,
      name: 'Comcast Corporation Class A',
      exchange: 'Nasdaq',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'WMT',
      approve_uid: '',
      report_id: '142',
      name: 'Walmart Inc.',
      count: 0,
      exchange: 'NYSE',
      update_uid: '2',
      countryOfIncorporation: '',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'DHR',
      approve_uid: '',
      report_id: '152',
      name: 'Danaher Corporation',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'ISRG',
      approve_uid: '',
      report_id: '162',
      name: 'Intuitive Surgical Inc.',
      count: 0,
      exchange: 'NASDAQ',
      update_uid: '2',
      state: 0,
      countryOfIncorporation: '',
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'JPM',
      approve_uid: '',
      report_id: '172',
      count: 0,
      name: 'JPMorgan Chase & Co.',
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    },
    {
      ticker: 'HST',
      approve_uid: '',
      report_id: '2',
      count: 0,
      name: 'Host Hotels & Resorts Inc.',
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 2
    },
    {
      ticker: 'EIX',
      approve_uid: '',
      report_id: '52',
      name: 'Edison International',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '2',
      state: 0,
      create_uid: '2',
      frequency: 1
    }];
};

export const getReportsByStatus = async (input, data) => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/reportsByStatus/' + input, data).then(result=>{
  //   console.log(result);
  //   return result
  // })
  return [{
    ticker: 'NLSN',
    approve_uid: '',
    report_id: '8',
    name: 'Nielsen Holdings Plc',
    count: 0,
    exchange: 'NYSE',
    countryOfIncorporation: '',
    update_uid: '8',
    state: 0,
    create_uid: '8',
    frequency: 1
  },
    {
      ticker: 'ATO',
      approve_uid: '',
      report_id: '9',
      name: 'Atmos Energy Corporation',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '9',
      state: 0,
      create_uid: '9',
      frequency: 1
    }];
};

export const getAllReports = async (data) => {
  const {intranetUid} = data;
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/allReports', data).then(result=>{
  //   console.log(result);
  //   return result
  // })
  return [{
    ticker: 'NLSN',
    approve_uid: '',
    report_id: '8',
    name: 'Nielsen Holdings Plc',
    count: 0,
    exchange: 'NYSE',
    countryOfIncorporation: '',
    update_uid: '8',
    state: 0,
    create_uid: '8',
    frequency: 1
  },
    {
      ticker: 'ATO',
      approve_uid: '',
      report_id: '9',
      name: 'Atmos Energy Corporation',
      count: 0,
      exchange: 'NYSE',
      countryOfIncorporation: '',
      update_uid: '9',
      state: 0,
      create_uid: '9',
      frequency: 1
    }];
};

export const getCompanyInfo = async data => {
  // return await asyncFetchGet('http://localhost:3004/web/v1' + '/companyMapping/companyInfo', data).then(result=>{
  //   console.log(result);
  //   return result
  // })
  return {
    'exchange': 'NYSE',
    'ticker': 'STT'
  };
};


export const getTransactionSummary = (state, callback) => {
  const data = {
    isDetail: true,
    transaction_state: state
  };
  fetchGet(`${DATA_SERVER_URL}/web/v1/transaction/processing-summary`, data, callback);
};

export const getTransaction = (data, callback) => {
  fetchGet(`${DATA_SERVER_URL}/web/v1/transaction`, data, callback);
};

export const updateTransaction = (data, callback) => {
  fetchPut(`${DATA_SERVER_URL}/web/v1/transaction/`, data, callback);
};

export const pay = (data, callback) => fetchGet(`${DATA_SERVER_URL}/web/v1/payment/paypal/send`, data, callback);

export const getXbrlKeysData = (data, callback) => {
  fetchGet(`${DATA_SERVER_URL}/us-modelbots-processing/reports/${data.companyName}/${data.tableType}`, '', callback);
};

export const getTableData = (report_id, table_type, data, callback) => {
  fetchGet(`${DATA_SERVER_URL}/invbots-key/tables/${report_id}/${table_type}`, data, callback);
};

export const getReportsKey = (data, callback) => {
  fetchGet(`${DATA_SERVER_URL}/invbots-key/reports`, data, callback);
};

export const getAllInvbotsKeysSuggestions = (data, callback) => {
  fetchGet(`${DATA_SERVER_URL}/invbots-key/all_keys`, data, callback);
};

export const getInvbotsKeysSuggestions = (data, callback) => {
  fetchGet(`${DATA_SERVER_URL}/invbots-key/suggestion`, data, callback);
};

export const getCompanyNames = (data, callback) => {
  // fetchGet(`${DATA_SERVER_URL}/invbots-key/suggestion`, data, callback);
  callback([
    {
      'name': 'aapl',
      'display_name': 'AAPL'
    },
    {
      'name': 'company2',
      'display_name': 'Company 2'
    },
    {
      'name': 'company3',
      'display_name': 'Company 3'
    }
  ]);
};

export const saveInvbotsKeys = (data, callback) => {
  fetch(`${DATA_SERVER_URL}/invbots-key/reports/temp_company/temp_report_type/_mapInvbotsKey`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .then(res => {
      if (res.ok) {
        return res.json();
      } else {
        callback(null, res.status);
      }
    })
    .then(data => {
      callback(data, null);
    })
    .catch(err => {
      callback(null, err);
    });
};


