/**
 * Form GET request params
 * @param {object} obj
 * @returns {string} param string
 */
const queryObject2string = (obj) => {
  let queryString = '';
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      queryString += `${key}=${obj[key]}&`;
    }
  }
  return queryString;
};

/**
 * Send GET request
 * @param {string} url
 * @param {object} query
 * @param {object} options
 * @returns {object} response
 */
export const fetchGet = async (url, query = null, options = {}) => {
    const {headers, ...rest} = options;
    console.log(`---x-----line: 27----------\n`, `${url}?${queryObject2string(query)}`);

    const response = await fetch(`${url}${
      query
        ? `?${queryObject2string(query)}`
        : ''
    }`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      ...rest
    });
    return await response.json(); // return json
  }
;

/**
 * Send POST request
 * @param {string} url
 * @param {object} payload
 * @param {object} options
 * @returns {object} response
 */
export const fetchPost = async (url, payload = {}, options = {}) => {
  // console.log(`---url, payload-----line: 53----------\n`, url, payload);
  const {headers, ...rest} = options;
  const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(payload),
      ...rest
    }
  );
  return await response.json();
};


/**
 * Send PUT request
 * @param {string} url
 * @param {object} payload
 * @param {object} options
 * @returns {object} response
 */
export const fetchPut = async (url, payload = {}, options = {}) => {
  const {headers, ...rest} = options;
  const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(payload),
      ...rest
    }
  );
  return await response.json();
};


/**
 * Send DELETE request
 * @param {string} url
 * @param {object} payload
 * @param {object} options
 * @returns {object} response
 */
export const fetchDelete = async (url, payload = {}, options = {}) => {
  const {headers, ...rest} = options;
  const response = await fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify(payload),
      ...rest
    }
  );
  return await response.json();
};
