import {COMPANY_SITE_URL, IS_LOCAL, USERS_API} from '../utilities/constantsFile';
import {fetchGetCors} from '../service/sendRequest';
import * as firebase from 'firebase';
import 'firebase/auth';
import * as config from '../config';
import {fetchGet} from '../service/fetchHelperFn';
import {mergeUser} from './userDataMerge';

if (!firebase.apps.length) {
  firebase.initializeApp(config.firebase);
}
// const user = {
//   'uid': 10,
//   'username': 'cheng.yu',
//   'password': 'CVBnm,123',
//   'role_name': 'normal',
//   'role': [
//     2
//   ],
//   'createTime': '2018-12-14 00:00:00',
//   'lastLoginTime': null,
//   'status': 1
// };

// export demault function getUserData(callback) {
//   callback(user);
// }

// import fetchPost from '../service/sendRequest';


export default async function getUserData() {
  if (IS_LOCAL)
    return {
      'status': 1,
      'user': {'_id': '5d12f72d379e8d339e4220f4', 'uuid': 'd714fb1d-0a02-4d64-9cce-2f85675df516', 'intranetUid': 6, 'display_name': 'a', 'email': null, 'create_time': '2019-01-11T00:00:00.000Z', 'profile_photo': 'https://invbots-users.sgp1.digitaloceanspaces.com/d/d714fb1d-0a02-4d64-9cce-2f85675df516/d714fb1d-0a02-4d64-9cce-2f85675df516=1565950575164=Capture.PNG', 'last_login_time': 1562732028820, 'is_active': true, 'gender': 1, 'email_is_verified': true, 'last_name': null, 'first_name': null, 'mobile_is_verified': false, 'role': [3]}
    };
  else {
    const firebaseUser = await firebase.auth().currentUser;
    if (firebaseUser) {
      //console.log('!~~~', firebaseUser);
      const invbotsUser = await fetchGet(USERS_API, {firebase_id: firebaseUser.uid});
      // merge invbots user data && firebase user data
      const mergedUser = mergeUser(invbotsUser, firebaseUser);
      //console.log(mergedUser);
      if (mergedUser.isActive && !!mergedUser.intranetUid) {
        //console.log(mergedUser);
        return mergedUser;
      } else
        return {};
    } else
      return {};
  }
  ;
}


export function asyncGetUserData() {
  if (IS_LOCAL)
    return {
      'status': 1,
      'user': {'_id': '5d12f72d379e8d339e4220f4', 'uuid': 'd714fb1d-0a02-4d64-9cce-2f85675df516', 'intranetUid': 6, 'display_name': 'a', 'email': null, 'create_time': '2019-01-11T00:00:00.000Z', 'profile_photo': 'https://invbots-users.sgp1.digitaloceanspaces.com/d/d714fb1d-0a02-4d64-9cce-2f85675df516/d714fb1d-0a02-4d64-9cce-2f85675df516=1565950575164=Capture.PNG', 'last_login_time': 1562732028820, 'is_active': true, 'gender': 1, 'email_is_verified': true, 'last_name': null, 'first_name': null, 'mobile_is_verified': false, 'role': [3]}
    };
  else
    return new Promise(resolve => {
        fetchGetCors(`${COMPANY_SITE_URL}/api/users/currentUser`, {}, result => {
          resolve(result);
        });
      }
    );
}

export function isEmpty(obj) {
  for (let key in obj) {
    if (obj.hasOwnProperty(key))
      return false;
  }
  return true;
}

export const signOut = async () => {
  await firebase.auth().signOut();
};
