import React, {Component} from 'react';
import {Nav, NavItem, NavLink} from 'reactstrap';
import PropTypes from 'prop-types';
import {AppNavbarBrand, AppSidebarToggler} from '@coreui/react';
import DefaultHeaderDropdown from './DefaultHeaderDropdown';

import logo from '../../assets/img/brand/logo.png';
import sygnet from '../../assets/img/brand/logo2.png';

const propTypes = {
  children: PropTypes.node
};

const defaultProps = {};

class DefaultHeader extends Component {
  constructor(props) {
    super(props);
    this.user = {};
  }

  render() {

    // eslint-disable-next-line
    // const { children, ...attributes } = this.props;

    return (
        <React.Fragment>
          <AppSidebarToggler className="d-lg-none" display="md" mobile/>
          <AppNavbarBrand
              full={{src: logo, width: 100, height: 40, alt: 'InvBots Logo'}}
              minimized={{src: sygnet, width: 30, height: 30, alt: 'InvBots Logo'}}
          />
          <AppSidebarToggler className="d-md-down-none h-100 b-r-1 px-3" display="lg"/>
          <Nav className="d-md-down-none" navbar>
            <NavItem className="px-3">
              <NavLink href="#/dashboard">DashBoard</NavLink>
            </NavItem>
            <NavItem className="px-3">
              <NavLink href="#/chartboard">ChartBoard</NavLink>
            </NavItem>
            {/*<NavItem className="px-3">*/}
            {/*<NavLink href="#/users">Users</NavLink>*/}
            {/*</NavItem>*/}
          </Nav>
          <Nav className="ml-auto" navbar>
            <DefaultHeaderDropdown accnt feedUser={this.props.userData}/>
          </Nav>
        </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
