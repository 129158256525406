import {
  ROW_CONTENT_CHANGED,
  ROW_CONTENT_SELECTED,
  ROW_CONTENT_CREATED,
  ROW_CONTENT_COPIED,
  ROW_CONTENT_PASTED,
  ROW_CONTENT_INIT,
  ROW_CONTENT_INIT_SUCCESS,
  ROW_CONTENT_INIT_FAIL,
  ROW_CONTENT_REMOVED,
  PASTE_POPUP_TOGGLE,
  ADD_DROPDOWN_ONCLICK,
  CLONE_DROPDOWN_ONCLICK,
  ROW_SELECT_MODE_CHANGED,
  MODAL_TOGGLED,
  ROWS_CONTENT_REMOVED,
  CLONE_DROPDOWN_ONCHANGE,
  ADD_DROPDOWN_ONCHANGE,
  ROW_CONTENT_RELOADED,
  UPDATE_LINK_REF,
  UPDATE_OPTION
} from '../../actions/types';

const INITIAL_STATE = {
  maxCount: 10,
  defaultCount: 10,
  addCount: 10,
  cloneCount: 10,
  counter: 0,
  keywordRows: [
    // {'category': '', 'subcategory': '', 'keyword': '', 'relevance': '',
    // 'trend': '', 'rationale': '', 'source': '', 'link': '', 'last_update': ''},
    // {'category': '', 'subcategory': '', 'keyword': '', 'relevance': '',
    //   'trend': '', 'rationale': '', 'source': '', 'link': '', 'last_update': ''},
    // {'category': '', 'subcategory': '', 'keyword': '', 'relevance': '',
    //   'trend': '', 'rationale': '', 'source': '', 'link': '', 'last_update': ''}
  ],
  addDropdownToggle: false,
  headers: [
    {'label': 'Category', 'value': 'category'},
    {'label': 'Sub-Category', 'value': 'subCategory'},
    {'label': 'Keywords', 'value': 'keyword'},
    {'label': 'Relevance', 'value': 'relevance'},
    {'label': '12M Trend', 'value': 'trend'},
    {'label': 'Rationale', 'value': 'rationale'},
    {'label': 'Sources', 'value': 'source'},
    {'label': 'Link', 'value': 'link'},
    {'label': 'Last updated', 'value': 'lastUpdate'},
  ],
  rowItemOptions: {},
  isLoading: true,
  isToggle: false,
  popupType: null,
  isMultipleSelectMode: false,
  modalType: 'search'
  // itemToCopied: null
};

const getCurrentTime = () => {
  const  today = new Date();
  return today.getTime()
};

const NEW_ROW = {'category': '', 'subCategory': '', 'keyword': '', 'relevance': '',
  'trend': '', 'rationale': '', 'source': '', 'link': '', 'lastUpdate': getCurrentTime(), 'state': 0};

export default (state = INITIAL_STATE, action) => {
  // console.log('[REDUCER] type:', action.type);
  let {keywordRows, rowItemOptions} = state;
  switch (action.type) {
    // case PAGE_INIT:
    //   return {...state,}
    case ROW_CONTENT_RELOADED:
      return state;
    case ROW_CONTENT_INIT:
      return {...state, ...action.payload, isLoading: true};
    case ROW_CONTENT_INIT_SUCCESS:
      const {rowItemOptions: initOptions, linkRef, ...rest} = action.payload;
      keywordRows = rest.keywordRows;
      if(keywordRows.length === 0 && keywordRows.constructor === Array)
        keywordRows = Array(state.defaultCount).fill(NEW_ROW);
      console.log(keywordRows);
      return {...state, ...rest, keywordRows, rowItemOptions: initOptions, linkRef, isLoading: false, isToggle: false};
    case ROW_CONTENT_INIT_FAIL:
      alert('ERROR');
      return {...state, ...action.payload, isLoading: false};
    case ADD_DROPDOWN_ONCLICK:
      return {...state, addDropdownToggle: !state.addDropdownToggle};
    case CLONE_DROPDOWN_ONCLICK:
      return {...state, ...action.payload, cloneDropdownToggle: !state.cloneDropdownToggle};
    case CLONE_DROPDOWN_ONCHANGE:
    case ADD_DROPDOWN_ONCHANGE:
      return {...state, ...action.payload};
    case ROW_CONTENT_CHANGED:
      const indexToChange = action.payload.idx;
      const rowToChange = {...state.keywordRows[indexToChange]};
      const {type, value} = action.payload;
      rowToChange[type] = value;
      if(type === 'source'){
        const ref = state.linkRef.find(ref=>{
          if(ref) return ref.source === value;
          else return false
        });
        rowToChange['link'] = ref? ref.link: '';
      }
      keywordRows[indexToChange] = rowToChange;
      return {...state, keywordRows: [...keywordRows]};
    case ROW_CONTENT_CREATED:
      const itemToCreated = Array(state.addCount).fill(NEW_ROW);
      return {...state, keywordRows: [...keywordRows, ...itemToCreated]};
    case ROW_CONTENT_COPIED:
      if(!state.isMultipleSelectMode){
        const indexToCopy = state.isSelect;
        const itemToCopied = state.keywordRows[indexToCopy];
        return {...state, itemToCopied};
      } else {
        // alert('You cannot copy in [Select Multiple] mode');
        return {...state, isToggle: !state.isToggle, modalType: 'warning-copy'};
      }
    case ROW_CONTENT_PASTED:
        const indexToPaste = state.isSelect + 1;
        let contentToPasted = {...state.itemToCopied};
        contentToPasted.lastUpdate = getCurrentTime();
        const itemToPasted = Array(state.cloneCount).fill(contentToPasted);
        keywordRows.splice(indexToPaste, 0, ...itemToPasted);
        return {...state, keywordRows: [...keywordRows], isToggle: !state.isToggle};
    case PASTE_POPUP_TOGGLE:
      if(!state.isMultipleSelectMode)return {...state, isToggle: !state.isToggle, modalType: 'paste'};
       else {
        return {...state, isToggle: !state.isToggle, modalType: 'warning-paste'};
        // return state;
      }
    case ROW_CONTENT_SELECTED:
      const mode = state.isMultipleSelectMode;
      let indexSelected = action.payload.idx;
      if(mode){
        let isSelectArray = [...state.isSelect];
        const index = isSelectArray.indexOf(indexSelected);
        if(index !== -1) isSelectArray.splice(index, 1);
        else isSelectArray.push(indexSelected);
        indexSelected = isSelectArray;
      }
      return {...state, isSelect: indexSelected};
    case ROW_SELECT_MODE_CHANGED:
      let {isMultipleSelectMode, isSelect} = state;
      isMultipleSelectMode = !isMultipleSelectMode;
      isSelect = (isMultipleSelectMode)? []: null;
      return {...state, isMultipleSelectMode, isSelect};
    case ROW_CONTENT_REMOVED:
      if(state.isMultipleSelectMode){
        const rowAfterRemoved = state.keywordRows.filter( ( el, i ) => !state.isSelect.includes(i));
        return {...state, keywordRows: rowAfterRemoved, isSelect: []};
      } else {
        keywordRows.splice(state.isSelect, 1);
        return {...state, keywordRows: [...keywordRows], isSelect: null};
      }
    case UPDATE_LINK_REF:
      console.log(action.payload.linkRef);
      return {...state, linkRef: action.payload.linkRef};
    case UPDATE_OPTION:
      const {idx, type:optionType, value:newOption} = action.payload;
      switch (optionType) {
        case 'subCategory':
          rowItemOptions = updateSubcategoryOptions(keywordRows, rowItemOptions, newOption, idx);
          break;
        case 'keyword':
          rowItemOptions = updateKeywordOptions(keywordRows, rowItemOptions, newOption, idx);
          break;
        default:
          break;
      }
      console.log(rowItemOptions);
      return {...state, rowItemOptions};
    case MODAL_TOGGLED:
      return {...state, ...action.payload, isToggle: state.isToggle?state.isToggle:!state.isToggle};
    default:
      return state;
  }
};

const updateSubcategoryOptions = (keywordRows, rowItemOptions, newOption, idx) => {
  const {category} = keywordRows[idx];
  let itemIndex;
  let item = rowItemOptions['category'].find((option, i)=>{
    if(option.value === category){
      itemIndex = i;
      return true
    } else return false
  });
  if(item){
    console.log(item);
    let {parent_of: parentOf} = item;
    parentOf = parentOf?parentOf:[];
    parentOf.push(newOption);
    rowItemOptions['subCategory'] = parentOf;
    item = {...item, parent_of: parentOf};
    rowItemOptions['category'][itemIndex] =  item;
  }
  return rowItemOptions;
};

const updateKeywordOptions = (keywordRows, rowItemOptions, newOption, idx) => {
  const {category, subCategory} = keywordRows[idx];
  let categoryIndex, subCategoryIndex, categoryItem, subCategoryItem;
  categoryItem = rowItemOptions['category'].find((option, i)=>{
    if(option.value === category){
      categoryIndex = i;
      return true
    } else return false
  });

  if(categoryItem && !subCategoryItem){
    console.log('here');
    let {category_of: categoryOf} = categoryItem;
    categoryOf = categoryOf?categoryOf:[];
    categoryOf.push(newOption);
    rowItemOptions['category'][categoryIndex] = {...rowItemOptions['category'][categoryIndex], category_of:categoryOf};
  }

  if(subCategory && categoryItem){
    const {parent_of: parentOf} = categoryItem;
    subCategoryItem = parentOf.find((option, i)=>{
      if(option.value === subCategory){
        subCategoryIndex = i;
        return true
      } else return false
    });
    if(subCategoryItem){
      let {category_of: subCategoryCategoryOf} = parentOf[subCategoryIndex];
      subCategoryCategoryOf.push(newOption);
      parentOf[subCategoryIndex] = {...parentOf[subCategoryIndex], category_of: subCategoryCategoryOf};
      console.log(parentOf);
      rowItemOptions['category'][categoryIndex] = {...rowItemOptions['category'][categoryIndex], parent_of: parentOf};
      console.log(rowItemOptions['category'][categoryIndex])
    }
  }

  console.log(rowItemOptions);

  return rowItemOptions;
};
