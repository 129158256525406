export default {
  items: [
    {
      name: 'Admin',
      url: '/admin',
      icon: 'icon-note',
      children: [
        {
          name: 'Users',
          url: '/admin/users'
        },
        {
          name: 'Stock Analyser Admin',
          url: '/admin/stan-admin'
        },
        {
          name: 'Payment',
          url: '/admin/payments'
        },
        {
          name: 'Payment Payouts',
          url: '/admin/payments/payouts'
        },
        {
          name: 'Payment History',
          url: '/admin/payments/history'
        }
      ]
    },
    {
      name: 'Reports Editing Module',
      url: '/reports_edit_module',
      icon: 'icon-note',
      children: [
        {
          name: 'Instructions',
          url: '/reports_edit_module/instructions'
        },
        {
          name: 'View',
          url: '/reports_edit_module/report_views',
          badge: {
            variant: 'danger',
            text: 'IMPORTANT'
          }
        },
        {
          name: 'Edit',
          url: '/reports_edit_module/reports_edit',
          badge: {
            variant: 'danger',
            text: 'IMPORTANT'
          }
        },
        // {
        //   name: 'Revert Table',
        //   url: '/reports_edit_module/revert_table'
        // },
        {
          name: 'Input Sheet',
          url: '/reports_edit_module/input_sheet'
        }

      ]
    },
    // {
    //   name: 'SEC',
    //   url: '/sec',
    //   icon: 'icon-info'
    // },
    // {name: 'Schedule Job Report', url: '/schedule_job', icon: 'icon-list'},
    // {
    //   name: 'Company Mapping',
    //   url: '/company-mapping',
    //   icon: 'icon-graph',
    //   children: [
    //     {
    //       name: 'Form',
    //       url: '/company-mapping/keywords-editor'
    //       // icon: 'icon-note',
    //     },
    //   ]
    // },
    {
      name: 'Xbrl Key Grouping',
      url: '/xbrl_key_grouping',
      icon: 'icon-note',
      children: [
        {
          name: 'Xbrl Key Grouping',
          url: '/xbrl_key_grouping/xbrl_key_grouping'
        }
      ]
    },
    // {
    //   name: 'Beta Test',
    //   url: '/beta-test',
    //   icon: 'icon-wrench',
    //   children: []
    // },
    // {
    //   name: 'BA Tools',
    //   url: '/batools',
    //   icon: 'icon-wrench',
    //   children: [
    //     {
    //       name: 'External Web Services',
    //       url: '/batools/external_web_services'
    //     },
    //     {
    //       name: '8K Report Viewer',
    //       url: '/batools/report-viewer'
    //     }
    //   ]
    // },
    // {
    //   name: 'HSI',
    //   url: '/hsi',
    //   icon: 'icon-note',
    //   children: [
    //     {
    //       name: 'HSI Table',
    //       url: '/hsi/hsi_table',
    //       icon: 'icon-note'
    //     },
    //     {
    //       name: 'HSI Contribution',
    //       url: '/hsi/hsi_contribution',
    //       icon: 'icon-note'
    //     }
    //   ]
    // },
    {
      name: 'ChatBot',
      url: '/chatbot',
      icon: 'icon-note',
      children: [
        {
          name: 'View Knowledge Base',
          url: '/chatbot/view_kb'
        },
        {
          name: 'Update Knowledge Base',
          url: '/chatbot/update_kb'
        }
      ]
    },
    {
      name: 'Investment Club',
      url: '/investment-club',
      icon: 'icon-plane',
      children: [
        {
          name: 'View Positions',
          url: '/investment-club/positions'
        }
      ]
    },
    {
      name: 'Equation Builder',
      url: '/equation_builder',
      icon: 'icon-pie-chart'
    }
  ]
};
