import { combineReducers } from "redux";
// import AuthReducer from "./AuthReducer";
import KeywordEditorReducer from './KeywordEditorReducer';
import KeywordEditorRowReducer from './KeywordEditorRowReducer';
// import RegisterReducer from './RegisterReducer';

const CompanyMappingModuleReducer =  combineReducers({
  // auth: AuthReducer
  keyword: KeywordEditorRowReducer,
  keywordsEditor: KeywordEditorReducer,
   // register: RegisterReducer
});

export default CompanyMappingModuleReducer;