require('dotenv').config();
//- General Setting
let isDataProduction, isWebProduction, isLocalhost;
if (process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'uat') {
  isDataProduction = true;
  isWebProduction = true;
  isLocalhost = false;
} else if (process.env.ENVIRONMENT === 'development') {
  isDataProduction = false;
  isWebProduction = true;
  isLocalhost = false;
} else if (process.env.ENVIRONMENT === 'localhost') {
  isDataProduction = false;
  isWebProduction = false;
  isLocalhost = true;
}
// const {isDataProduction, isWebProduction, isLocalhost} = isProduction;

export const COMPANY_SITE_URL = isDataProduction ? 'https://www.invbots.com' : 'https://www.invbots.dev';
export const LOCAL_URL = isDataProduction ? 'https://internal.invbots.com/intranet/#' : 'https://internal.invbots.dev/intranet/#';
export const DATA_SERVER_URL = isDataProduction ? 'https://api.invbots.com' : isLocalhost ? 'http://localhost:3001' : 'https://api.invbots.dev';
export const DATA_SERVER_URL3 = 'http://159.89.194.50/intranet-api-test/report-edit';

export const FLASK_SERVER_URL = 'http://167.99.74.217';

export const INTRANET_API_SERVER_URL = DATA_SERVER_URL + '/intranet-api'; // IMPORTANT+
export const GRAPHQL_URL = INTRANET_API_SERVER_URL + '/graphql';
// export const GRAPHQL_URL = 'http://localhost:3001/intranet-api/graphql';
export const WEB_API_SERVER_URL = DATA_SERVER_URL + '/web/v1';

export const API_SERVER_URL = DATA_SERVER_URL + '/api';

export const USERS_API = `${WEB_API_SERVER_URL}/users`;


// export const LOCAL_URL = 'http://localhost:8000';
//- Report Edit Setting
export const REPORT_EDIT_URL = INTRANET_API_SERVER_URL + '/report-edit';  // real

//- Equation Builder
export const EQUATION_BUILDER_URL = INTRANET_API_SERVER_URL + '/equation-builder';

//- Config Files
export const IS_PRODUCTION = isWebProduction;
export const IS_LOCAL = isLocalhost;
export const NAV_TOOL_CONFIG = 'nav_tools_config.json';
export const ROUTE_CONFIG = 'routes_config.json';
export const AUTH_CONFIG = 'auth_config.json';

// export const REPORT_EDIT_URL = 'http://159.89.194.50/intranet-api-test/report-edit';  //test
export const REPORT_LOCATION = [
  {name: 'Hong Kong', abbr: 'hk'},
  {name: 'United State', abbr: 'us'},
  {name: 'China', abbr: 'cn'}];

export const REPORT_EDIT_EXCHANGE = ['sehk', 'nyse', 'nasdaq', 'szse', 'sse'];
export const REPORT_EDIT_ROLE = ['checker', 'supervisor', 'leader'];
export const REPORT_EDIT_REPORT_LANG = ['en', 'zh-hk', 'zh-cn'];
export const REPORT_EDIT_PERIOD = ['q1', 'q2', 'q3', 'q4', 'fy'];
export const REPORT_EDIT_TABLE_NAME = ['is', 'bs', 'cfs', 'cis', 'cfs_note'];
export const REPORT_EDIT_REPORT_TYPE = ['an', 'ar', 'ir', 'pr', 'qr', '6-k', '8-k', '10-k', '10-q', '20-f'];
export const ALPHABET_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
export const STATUS_LIST = {'0': 'Pending', '1': 'Requested', '2': 'Approved', '3': 'Paid', '-1': 'Rejected'};
export const EMPTY_STRING = 'N/A';
