/**
 *  @author: Kenny Chan <kenny.chan@invbot.com>
 *  @since: 2018-12-04
 */
import React from 'react';
import Loadable from 'react-loadable';
import DefaultLayout from './containers/DefaultLayout';

const Loading = () => {
  return <div>Loading...</div>;
};

// const Dashboard = Loadable({loader: () => import('./views/Dashboard'), loading: Loading});
const ReportEdit = Loadable({loader: () => import('./views/ReportEditModule/ReportEdit'), loading: Loading});
const ReportEditInstructions = Loadable({loader: () => import('./views/ReportEditModule/Instructions'), loading: Loading});
// const ReportRevertTable = Loadable({loader: () => import('./views/ReportEditModule/RevertTable'), loading: Loading});
const ReportViews = Loadable({loader: () => import('./views/ReportEditModule/ReportViews'), loading: Loading});
const ReportInputSheet = Loadable({loader: () => import('./views/ReportEditModule/InputSheet/InputData/InputData'), loading: Loading}); // todo edit it later
const ChartBoard = Loadable({loader: () => import('./views/ChartBoard'), loading: Loading});
const ResultAnnouncement = Loadable({loader: () => import('./views/Sec/ResultAnnouncement'), loading: Loading});
const XbrlKeyGrouping = Loadable({loader: () => import('./views/XbrlKeyGrouping/XbrlKeyGrouping'), loading: Loading});
const Login = Loadable({loader: () => import('./views/Pages/Login'), loading: Loading});
const Users = Loadable({loader: () => import('./views/Users/Users'), loading: Loading});
const ScheduleJobTable = Loadable({loader: () => import('./views/ScheduleJobTable/ScheduleJobTable'), loading: Loading});
const ExternalWebServices = Loadable({loader: () => import('./views/BATools/ExternalWebServices'), loading: Loading});
const HSITable = Loadable({loader: () => import('./views/HSI/HSITable/HSITable'), loading: Loading});
const HSIContribution = Loadable({loader: () => import('./views/HSI/HSIContribution/HSIContribution'), loading: Loading});
const BetaTestTableContainer = Loadable({loader: () => import('./views/Tools/BetaTestTableContainer'), loading: Loading});
const BetaTestHome = Loadable({loader: () => import('./views/Tools/BetaTest'), loading: Loading});
const StanAdmin = Loadable({loader: () => import('./views/Tools/StanAdmin'), loading: Loading});
const Payments = Loadable({loader: () => import('./views/Payments/Payment'), loading: Loading});
const PaymentsPayouts = Loadable({loader: () => import('./views/Payments/PaymentPayouts'), loading: Loading});
const PaymentHistory = Loadable({loader: () => import('./views/Payments/PaymentHistory'), loading: Loading});
const EquationBuilder = Loadable({loader: () => import('./views/EquationBuilder/EquationBuilderPage'), loading: Loading});
const UpdateKB = Loadable({loader: () => import('./views/ChatBot/KnowledgeBase/UpdateKnowledgeBasePage.js'), loading: Loading});
const ViewKB = Loadable({loader: () => import('./views/ChatBot/KnowledgeBase/ViewKnowledgeBasePage.js'), loading: Loading});
const CompanyMappingModule = Loadable({loader: () => import('./views/CompanyMappingModule/CompanyMappingModule.js'), loading: Loading});
const KeywordsEditor = Loadable({loader: () => import('./views/CompanyMappingModule/KeywordsEditor/KeywordsEditor.js'), loading: Loading});
const CompanyMappingUserReport = Loadable({loader: () => import('./views/CompanyMappingModule/ReportViewer/UserReport.js'), loading: Loading});
const CompanyMappingAdminReport = Loadable({loader: () => import('./views/CompanyMappingModule/ReportViewer/AdminReport.js'), loading: Loading});
const CompanyMappingApprovalPage = Loadable({loader: () => import('./views/CompanyMappingModule/KeywordApprover/KeywordApprover.js'), loading: Loading});
const ReportViewer = Loadable({loader: () => import('./views/ReportViewer/ReportViewerHome'), loading: Loading});
// const ReportViewerTable = Loadable({loader: () => import('./views/ReportViewer/ReportViewerHome'),loading: Loading});
// const TableViewer = Loadable({loader: () => import('./views/ReportViewer/TableViewerHome'),loading: Loading});
const DateReportViewer = Loadable({loader: () => import('./views/ReportViewer/DateReportViewer'), loading: Loading});
const PositionView = Loadable({loader: () => import('./views/InvestmentClub/Positions'), loading: Loading});
// const DOI = Loadable({loader: () => import('./views/Tools/BetaTest/ProgramConstructor'), loading: Loading});
//
// const ExportModelbots = Loadable({loader: () => import('./views/Tools/ExportModelbots'), loading: Loading});

//
const routes = [
  //-- Default Layout
  {path: '/', name: 'Home', component: DefaultLayout, exact: true},
  //-- Dash Board
  // {path: '/dashboard', name: 'Dashboard', component: Dashboard},
  //-- Report Edit Module
  {path: '/reports_edit_module', name: 'Reports Edit Module', component: ReportEditInstructions, exact: true},
  {path: '/reports_edit_module/instructions', name: 'Instructions', component: ReportEditInstructions},
  {path: '/reports_edit_module/reports_edit', name: 'Reports Edit Page', component: ReportEdit},
  // {path: '/reports_edit_module/revert_table', name: 'Reports Revert Table Page', component: ReportRevertTable},
  {path: '/reports_edit_module/report_views', name: 'Reports Views Page', component: ReportViews},
  {path: '/reports_edit_module/input_sheet', name: 'Reports Input Sheet', component: ReportInputSheet},
  //-- Chart Board
  {path: '/chartboard', name: 'ChartBoard', component: ChartBoard},
  //-- SEC
  {path: '/sec', name: 'SEC', component: ResultAnnouncement, exact: true},
  {path: '/sec/result_announcement', name: 'Result Announcement', component: ResultAnnouncement},
  //-- Login
  {path: '/Pages/Login', name: 'Login', component: Login},
  //-- Schedule Job
  {path: '/schedule_job', name: 'Schedule Job Table', component: ScheduleJobTable},
  //-- Company Mapping
  {path: '/company-mapping/keywords-editor/:report_id', name: 'Keyword Editor', component: KeywordsEditor},
  {path: '/company-mapping/keywords/user', name: 'User Report', component: CompanyMappingUserReport},
  {path: '/company-mapping/keywords/admin/:uid', name: 'Admin Report', component: CompanyMappingAdminReport},
  {path: '/company-mapping/keywords/approval/:report_id', name: 'Keyword Approval', component: CompanyMappingApprovalPage},
  {path: '/company-mapping', name: 'Company Mapping', component: CompanyMappingModule},
  //-- xbrl_key_grouping
  {path: '/xbrl_key_grouping/xbrl_key_grouping', name: 'Xbrl Key Grouping', component: XbrlKeyGrouping, exact: true},
  //-- BATools
  {path: '/batools', name: 'BATools', component: ExternalWebServices, exact: true},
  {path: '/batools/external_web_services', name: 'External Web Services', component: ExternalWebServices},
  {path: '/batools/report-viewer', name: '8K Report Viewer Selection', component: ReportViewer},
  // {path: '/batools/report-viewer-table',name: '8K Report Viewer Selection',component: ReportViewerTable},
  // {path: '/batools/table-viewer',name: '8K Report Table Viewer',component: TableViewer},
  {path: '/batools/date-report-viewer', name: '8K Date Report Viewer', component: DateReportViewer},
  //-- Tools
  {path: '/beta-test', name: 'Tools', component: BetaTestHome, exact: true},
  {path: '/beta-test/home', name: 'Home', component: BetaTestHome},
  //-- HSI
  {path: '/hsi', name: 'HSI', component: HSITable, exact: true},
  {path: '/hsi/hsi_table', name: 'HSI Table', component: HSITable},
  {path: '/hsi/hsi_contribution', name: 'HSI Contribution', component: HSIContribution},
  //-- Beta Test
  {path: '/beta-test/beta-test-table', name: 'Beta Tests', component: BetaTestTableContainer},
  //-- Admin
  {path: '/admin', name: 'Admin', component: Users, exact: true},
  {path: '/admin/users', name: 'Users', component: Users},
  {path: '/admin/stan-admin', name: 'Stock Analyser Admin', component: StanAdmin},
  {path: '/admin/payments', name: 'Payment', component: Payments, exact: true},
  {path: '/admin/payments/payouts', name: 'Payments Paid Table', component: PaymentsPayouts, exact: true},
  {path: '/admin/payments/history', name: 'Payment History', component: PaymentHistory, exact: true},
  {path: '/investment-club/positions', name: 'View Positions', component: PositionView},
  {path: '/chatbot/view_kb', name: 'View Knowledge Base', component: ViewKB},
  {path: '/chatbot/update_kb', name: 'Update Knowledge Base', component: UpdateKB},
  //-- Equation builder
  {path: '/equation_builder', name: 'Equation Builder', component: EquationBuilder}
];

// {path: '/tools/doi-analysis', name: 'DOI', component: DOI},
// {path: '/tools/export_modelbots', name: 'Export Modelbots', component: ExportModelbots},

// config.forEach(route => {
//   const {path, name} = route;
//   route['component'] = Loadable({loader: () => import('./views/Tools/BetaTest/ProgramConstructor'), loading: Loading});
//   // route['path'] = '/tools/doi-analysis';
//   // route['name'] = 'DOI';
//   routes.push(route);
// });


export default routes;
